import React, { useState } from "react";
import { BiMenu } from "react-icons/bi";
import Logo from "../images/logo-no-background.png";

function Menu() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLinkClick = () => {
    setShowMenu(false);
  };

  return (
    <nav className="flex  w-screen items-center justify-between  ">
      <div className="hidden md:fixed md:left-0 md:top-0 md:z-40">
        <img src={Logo} alt="logo firmy" className=" w-22 h-12" />
      </div>
      <ul
        className={`md:flex ${showMenu ? "block " : "hidden"} md:pos md:animate-fadeIn fixed left-0 top-0 z-30 w-full items-center justify-center   gap-5 bg-black p-3 text-center text-sm uppercase text-amber-400 md:static md:bg-transparent `}
      >
        <li>
          <a href="#dlaczego-my" onClick={handleLinkClick}>
            O firmie
          </a>
        </li>
        <li>
          <a href="#oferta" onClick={handleLinkClick}>
            Oferta
          </a>
        </li>
        <li>
          <a href="#realizacje" onClick={handleLinkClick}>
            Realizacje
          </a>
        </li>
        <li>
          <a href="#kontakt" onClick={handleLinkClick}>
            Kontakt
          </a>
        </li>
      </ul>

      <button
        className="fixed right-0 top-0 z-40 block border-0 p-2 md:hidden"
        onClick={toggleMenu}
      >
        <BiMenu className="text-2xl text-white" />
      </button>
    </nav>
  );
}

export default Menu;
