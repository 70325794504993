import photo1 from "../images/gallery1.jpg";
import photo2 from "../images/gallery2.jpg";
import photo3 from "../images/gallery3.jpg";
import photo4 from "../images/gallery4.jpg";
import photo5 from "../images/gallery5.jpg";
import photo6 from "../images/gallery6.jpg";
import photo7 from "../images/gallery7.jpg";
import photo8 from "../images/gallery8.jpg";
import photo9 from "../images/gallery9.jpg";
import photo10 from "../images/gallery10.jpg";
import photo11 from "../images/gallery11.jpg";
import photo12 from "../images/gallery12.jpg";

import useIntersectionObserver from "./useIntersectionObserver";
import { useState } from "react";

function Gallery() {
  const data = [
    { imgelink: photo1 },
    { imgelink: photo2 },
    { imgelink: photo3 },
    { imgelink: photo4 },
    { imgelink: photo5 },
    { imgelink: photo6 },
    { imgelink: photo7 },
    { imgelink: photo8 },
    { imgelink: photo9 },
    { imgelink: photo10 },
    { imgelink: photo11 },
    { imgelink: photo12 },
  ];

  const [active, setActive] = useState(photo11);
  const [refs, visibleElements] = useIntersectionObserver();

  return (
    <div className="p-2" id="realizacje">
      <div
        className={`mt-8 text-center text-amber-950 transition-all duration-1000 ease-out ${
          visibleElements.includes(refs.current[0]?.current)
            ? "animate-fadeInSlideFromDown"
            : "-translate-y-24 transform opacity-0"
        }`}
        ref={refs.current[0]}
      >
        <h1 className="text-3xl font-medium text-amber-950">realizacje</h1>
        <p className="mt-1 text-sm font-light ">od projektu do wykonania</p>
      </div>
      <div className="mt-8 grid justify-items-center gap-4 ">
        <div className="relative">
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center  md:h-[440px]"
            src={active}
            alt=""
          />

          <div className="absolute inset-0 rounded-lg bg-amber-950 opacity-20"></div>
        </div>
        <div className="grid grid-cols-4 gap-4 md:grid-cols-6">
          {data.map(({ imgelink }, index) => (
            <div key={index}>
              <img
                onClick={() => setActive(imgelink)}
                src={imgelink}
                className="h-16 max-w-full cursor-pointer rounded-lg object-cover object-center"
                alt="gallery-image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Gallery;
