import photo from "../images/bailey-alexander-73AQtTlAtNg-unsplash.jpg";
import {
  TbCircleNumber1,
  TbCircleNumber2,
  TbCircleNumber3,
} from "react-icons/tb";
import useIntersectionObserver from "./useIntersectionObserver";

function CompanyDescription() {
  const [refs, visibleElements] = useIntersectionObserver();
  return (
    <section class=" py-8 sm:py-16 ">
      <div
        className={`mx-auto max-w-7xl px-2 transition-all duration-1000 ease-out lg:px-8 ${
          visibleElements.includes(refs.current[0]?.current)
            ? "animate-fadeInSlideFromDown"
            : "-translate-y-24 transform opacity-0"
        }`}
        ref={refs.current[0]}
      >
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:justify-center">
          <div class="lg:pr-8 lg:pt-4">
            <div class="lg:max-w-lg">
              <h2 class="text-base font-semibold leading-7 text-amber-400">
                Zaufaj jakości
              </h2>
              <p class="mt-2 text-3xl font-bold tracking-tight text-amber-950 sm:text-4xl">
                Kompleksowe usługi
              </p>
              <p class="mt-6 text-lg leading-8 text-gray-950">
                Zastawiasz się jak wygląda realizacja zamówień? Tak pracujemy:
              </p>
              <div class="mt-10 max-w-xl space-y-2 text-base leading-7 text-gray-950 lg:max-w-none">
                <div className="flex  items-start gap-4 ">
                  <div>
                    <TbCircleNumber1 className=" text-4xl text-amber-400" />
                  </div>
                  <p>
                    Umówienie się na wizytę, zebranie informacji oraz wykonanie
                    pomiarów
                  </p>
                </div>
                <div className="flex  items-start gap-4 ">
                  <div>
                    <TbCircleNumber2 className=" text-4xl text-amber-400" />
                  </div>
                  <p>Wstępna wycena, określenie czasu realizacji</p>
                </div>
                <div className="flex  items-start gap-4 ">
                  <div>
                    <TbCircleNumber3 className=" text-4xl text-amber-400" />
                  </div>
                  <p>Realizacja projektu. Budowa oraz transport</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img src={photo} alt="test"></img>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompanyDescription;
