import { FaCheckCircle } from "react-icons/fa";

import useIntersectionObserver from "./useIntersectionObserver";

function OurOffer() {
  const [refs, visibleElements] = useIntersectionObserver();

  return (
    <div
      id="oferta"
      className={`pb-16 pl-1 pr-1 transition-all duration-1000 ease-out ${
        visibleElements.includes(refs.current[0]?.current)
          ? "animate-fadeInSlideFromDown"
          : "-translate-y-24 transform opacity-0"
      }`}
      ref={refs.current[0]}
    >
      <div className="mt-8 text-center text-amber-950 md:mb-16 md:mt-12">
        <h1 className="text-3xl font-medium text-amber-950">nasza oferta</h1>
        <p className="mt-1 text-sm font-light ">
          sprawdź czym dokładnie się zajmujemy
        </p>
      </div>
      <div className=" mt-5 flex flex-col justify-center gap-3  sm:items-start  sm:justify-start md:grid md:grid-cols-3 xl:mt-14 2xl:mx-auto 2xl:mt-14 2xl:max-w-[1800px] ">
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">meble kuchenne</p>
        </div>
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">meble biurowe oraz pokojowe</p>
        </div>
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">meble do jadalni, salonu, sypialni</p>
        </div>
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">szafy i komody</p>
        </div>
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">stoły oraz łóżka</p>
        </div>
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">zabudowy wnęk</p>
        </div>
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">schody i parapety</p>
        </div>
        <div className="flex items-center justify-start gap-3">
          <div>
            <FaCheckCircle className="text-2xl text-amber-400" />
          </div>
          <p className="text-amber-950">wiele innych</p>
        </div>
      </div>
    </div>
  );
}

export default OurOffer;
