import React from "react";
import { HiBuildingOffice } from "react-icons/hi2";
import { FaHammer, FaTruck, FaListUl } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import useIntersectionObserver from "./useIntersectionObserver";

function AboutUs() {
  const numCards = 7;
  const [refs, visibleElements] = useIntersectionObserver(numCards);

  const cards = [
    {
      icon: <HiBuildingOffice className="text-6xl text-amber-400 " />,
      title: "Wieloletnie Doświadczenie",
      description:
        "Od 1981 roku, z pasją i doświadczeniem, tworzymy wyjątkowe meble, które zdobią wnętrza naszych klientów. Nasza firma to synonim jakości i tradycji w branży meblarskiej.",
    },
    {
      icon: <FaHammer className="text-6xl text-amber-400 " />,
      title: "Park Maszynowy",
      description:
        "Nasza firma wyróżnia się nowoczesnym parkiem maszynowym, który pozwala na precyzyjne i efektywne wykonywanie najwyższej jakości mebli na składane zamówienia.",
    },
    {
      icon: <BsFillPeopleFill className="text-6xl text-amber-400 " />,
      title: "Indywidualne Podejście",
      description:
        "Do każdego klienta podchodzimy z nowym zapałem, co pozwala nam realizować wyjątkowe i w pełni spersonalizowane projekty mebli, spełniające nawet najbardziej wyszukane oczekiwania.",
    },
    {
      icon: <FaTruck className="text-6xl text-amber-400 " />,
      title: "Własny Transport",
      description:
        "Kompleksowe usługi, w tym profesjonalny transport, który zapewnia bezpieczne i terminowe dostarczenie mebli do klienta.",
    },
    {
      icon: (
        <MdOutlineScreenSearchDesktop className="text-6xl text-amber-400 " />
      ),
      title: "Szybkość i Dokładność",
      description:
        "Dokładne pomiary w domu klienta, a następnie projekt mebli oraz wycenę, z użyciem profesjonalnego programu komputerowego.",
    },
    {
      icon: <FaListUl className="text-6xl text-amber-400 " />,
      title: "Szeroki Wybór Materiałów",
      description:
        "Meble tradycyjne, stylowe- z drewna krajowego i egzotycznego jak i nowoczesne- wykorzystując najnowsze technologie i materiały.",
    },
  ];

  return (
    <div id="dlaczego-my" className="pb-16">
      <div
        className={`mt-8 text-center text-amber-950 transition-all duration-1000 ease-out ${
          visibleElements.includes(refs.current[0]?.current)
            ? "animate-fadeInSlideFromDown"
            : "-translate-y-24 transform opacity-0"
        }`}
        ref={refs.current[0]}
      >
        <h1 className="text-3xl font-medium text-amber-950">dlaczego my?</h1>
        <p className="mt-1 text-sm font-light ">
          rodzinna firma z wieloletnim doświadczeniem
        </p>
      </div>
      <div className="md:align-center flex flex-col items-center justify-center gap-2 md:grid md:grid-cols-2 md:items-start md:justify-items-center lg:grid-cols-3 2xl:m-auto 2xl:max-w-[1800px]">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`mt-8 flex w-[90%] max-w-lg flex-col items-start justify-center gap-2 p-4 text-bronze-500 shadow-md transition-all transition-opacity duration-1000 hover:shadow-lg md:w-[100%] ${
              visibleElements.includes(refs.current[index + 1]?.current)
                ? "opacity-100"
                : "opacity-0"
            }`}
            ref={refs.current[index + 1]}
          >
            {card.icon}
            <h3 className="text-2xl font-semibold tracking-tight text-amber-400">
              {card.title}
            </h3>
            <p className="text-amber-950">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutUs;
