import useIntersectionObserver from "./useIntersectionObserver";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

function Footer() {
  const [refs, visibleElements] = useIntersectionObserver();

  return (
    <div
      id="kontakt"
      className={`trans ml-auto  mr-auto transition-all duration-1000 ease-out 2xl:max-w-[1800px] ${
        visibleElements.includes(refs.current[0]?.current)
          ? "animate-fadeInSlideFromDown"
          : "-translate-y-24 transform opacity-0"
      }`}
      ref={refs.current[0]}
    >
      {" "}
      <h1 className="relative ml-4 mt-4 inline-block text-6xl tracking-[-7px] text-amber-950 opacity-35 lg:tracking-[-13px] 2xl:max-w-[1800px] 2xl:text-center ">
        HEBAN
        <span className="absolute -right-4 -top-4  transform text-2xl font-black">
          ©
        </span>
      </h1>
      <footer className=" items-start justify-items-end  p-4  md:grid md:grid-cols-2fr-1fr 2xl:mx-auto 2xl:max-w-[1800px] ">
        <div>
          <p className=" text-gray-950">
            Nasza firma meblarska działa nieprzerwanie od 1981 roku,
            dostarczając najwyższej jakości meble, schody, szafy oraz inne
            elementy wyposażenia wnętrz. Dzięki wieloletniemu doświadczeniu i
            pasji do tworzenia, realizujemy nawet najbardziej wymagające
            projekty, dbając o każdy detal. Wierzymy, że nasze produkty
            przynoszą piękno i funkcjonalność do Państwa domów i biur.
            Zapraszamy do kontaktu.
          </p>
        </div>
        <div className="  text-amber-950">
          <h4 className=" text-3xl  text-amber-400 sm:mt-12 md:mt-0">
            dane kontaktowe:
          </h4>
          <div className=" mt-3  flex items-center gap-3">
            <MdEmail className="text-2xl text-amber-400" />
            <div>
              <a
                href="mailto:biuro@heban-meble.pl"
                className=" text-gray-950 hover:text-amber-400"
              >
                biuro@heban-meble.pl
              </a>
            </div>
          </div>
          <div className=" mt-2  flex items-center gap-3">
            <FaPhone className="text-2xl text-amber-400" />
            <div>
              <a
                href="tel:+48602449147"
                className=" text-gray-950 hover:text-amber-400"
              >
                602-449-147
              </a>
            </div>
          </div>
          <p className=" mt-4  text-center text-gray-950 sm:text-sm md:mt-1 md:text-base">
            2024 Wszystkie prawa zastrzeżone.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
