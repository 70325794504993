import BackgroundImg from "../images/todd-quackenbush-IClZBVw5W5A-unsplash.jpg";
import Button from "./Button";

function ParralaxMail() {
  return (
    <div
      style={{
        background: `linear-gradient(30deg, rgba(39, 22, 20, 0.6), rgba(39, 22, 20, 0.6)),url(${BackgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
      }}
      className="flex h-80 items-center justify-center"
    >
      <Button
        href="mailto:biuro@meble-heban.pl"
        className=" border-2 border-solid border-amber-300 px-4 py-2 text-amber-300 hover:border-amber-100  hover:text-amber-100 sm:text-xl lg:text-3xl"
      >
        biuro@meble-heban.pl
      </Button>
    </div>
  );
}

export default ParralaxMail;
