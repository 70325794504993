import background from "../images/will-suddreth-o54RjF-C7xo-unsplash.jpg";
import ButtonOffer from "./ButtonOffer";
import Menu from "./Menu";

function Header() {
  return (
    <>
      <header
        className="relative z-20 h-screen"
        style={{
          background: `linear-gradient(30deg, rgba(29, 22, 20, 0.6), rgba(125, 45, 25, 0.3)),url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Menu />
        <div className="absolute inset-0 z-10 mb-72 flex animate-fadeIn items-center justify-center text-center">
          <div>
            <h1 className="text-6xl tracking-[-8px] text-bronze-200 opacity-50 lg:mt-52 lg:text-8xl lg:tracking-[-13px]  ">
              HEBAN
            </h1>
            <p className=" font-thin tracking-widest text-bronze-100 opacity-50 ">
              stolarstwo meblowe z tradycją od 1981
            </p>
            <ButtonOffer />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
