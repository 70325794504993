import React, { useEffect, useRef, useState } from "react";

const useIntersectionObserver = (numElements) => {
  const [visibleElements, setVisibleElements] = useState([]);
  const refs = useRef([...Array(numElements)].map(() => React.createRef()));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleElements((prev) => [...prev, entry.target]);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 },
    );

    refs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  return [refs, visibleElements];
};

export default useIntersectionObserver;
