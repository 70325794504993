import BackgroundImg from "../images/bailey-alexander-73AQtTlAtNg-unsplash.jpg";
import Button from "./Button";

function ParallaxPhone() {
  return (
    <div
      style={{
        background: `linear-gradient(30deg, rgba(39, 22, 20, 0.6), rgba(39, 22, 20, 0.6)),url(${BackgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
      }}
      className="flex h-80 items-center justify-center"
    >
      <Button
        href="tel:+48602449147"
        className=" border-2 border-solid border-amber-300 px-4 py-2 text-3xl text-amber-300 hover:border-amber-100 hover:text-amber-100"
      >
        +48 602-449-147
      </Button>
    </div>
  );
}

export default ParallaxPhone;
