import "./Input.css";
import AboutUs from "./components/AboutUs";
import Header from "./components/Header";
import OurOffer from "./components/OurOffer";
import ParralaxPhone from "./components/ParralaxPhone";
import ParralaxMail from "./components/ParralaxMail";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import CompanyDescription from "./components/CompanyDescription";

function App() {
  return (
    <>
      <Header />
      <AboutUs />
      <CompanyDescription />
      <ParralaxPhone />
      <Gallery />
      <OurOffer />
      <ParralaxMail />
      <Footer />
    </>
  );
}

export default App;
