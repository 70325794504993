import Button from "./Button";

function ButtonOffer() {
  return (
    <Button
      className="  mt-5 inline-block rounded-lg border-2  border-solid border-amber-300 px-5 py-2 uppercase text-amber-300 shadow-2xl shadow-red-900 transition-colors duration-500 hover:border-double hover:border-amber-100 hover:text-amber-100 "
      href="#oferta"
    >
      oferta
    </Button>
  );
}

export default ButtonOffer;
